import React from 'react'
import './HomepageWhyLifepal.module.scss'
import { WHY_LIFEPAL_JSON } from '../../../shared/constants';
import HomepageSlider from '../../../components/Slider/HomepageSlider';

const HomepageWhyLifepal = () => (
  <div className='why-lifepal-wrapper'>
    <h2 className='why-lifepal-title'>Telah dipercayai lebih dari 52,000 nasabah</h2>
    <p className='why-lifepal-subtitle'>
      Mengapa puluhan ribu nasabah telah mempercayai Lifepal untuk membantu mereka dalam memilih dan mendapatkan proteksi asuransi terbaik?
    </p>
    <div className='why-lifepal-card-wrapper'>
      <HomepageSlider
        sliderData={{
          desktop: {
            height: 272,
            width: 262,
            visibleItems: 4,
          },
          mobile: {
            height: 272,
            width: 301,
          },
        }}
        items={
          WHY_LIFEPAL_JSON.map((item, index) => (
            <div
              className='why-lifepal-card'
              key={`why-lifepal-card-${index}`}
            >
              <img src={item.icon} alt="" className='why-lifepal-card-icon' />
              <p className='why-lifepal-card-title'>
                {item.title}
              </p>
              <p className='why-lifepal-card-description'>
                {item.description}
              </p>
            </div>
          ))
        }
      />
    </div>
  </div>
);

export default HomepageWhyLifepal;